import { Injectable } from '@angular/core';
import { SubjectsService } from './subjects.service';

@Injectable()
export class UserService {

    private users: User[] = [];
    private ownid: string = null;
    private usertag = [];

    public setOwnId(string): void {
        this.ownid = string;
    }

    public addUser(user: User): void {
        if (user.userid === this.ownid) {
            user.usertype = 'owner';
        } else {
            user.usertype = 'remote';
        }
        this.users.push(user);
    }
    public addMultiUser(users: User[]): void {
        this.users = [];
        for (const key in users) {
            if (users.hasOwnProperty(key)) {
                if (users[key].userid === this.ownid) {
                    users[key].usertype = 'owner';
                } else {
                    users[key].usertype = 'remote';
                }
                this.users.push(users[key]);
            }
        }
    }

    public addUserTag(tag, id): void {
        this.usertag[id] = tag;
    }

    public setAnalyser(analyser, id): void {
        for (const key in this.users) {
            if (this.users.hasOwnProperty(key)) {
                if (this.users[key].userid === id) {
                    this.users[key].analyser = analyser;
                }
            }
        }
    }
    public setAudio(audio, id): void {
        for (const key in this.users) {
            if (this.users.hasOwnProperty(key)) {
                if (this.users[key].userid === id) {
                    this.users[key].audio = audio;
                }
            }
        }
    }

    public delUserTag(id): void {
        delete this.usertag[id];
    }

    public delUser(name: string): void {
        const users = this.users.filter(n => n.name !== name);
        this.users = users;
    }

    public delUserByUserId(userid: string): void {
        const users = this.users.filter(n => n.userid !== userid);
        this.users = users;
    }

    public getUserTag(id): void {
        return this.usertag[id];
    }

    public getUserByName(name: string): User {
        const users = this.users.filter(n => n.name === name);
        return users[0];
    }
    public getUserById(id: string): User {
        const users = this.users.filter(n => n.userid === id);
        return users[0];
    }

    public getIndexByName(name: string): number {
        const index = this.users.filter((n, i, a) => {
            if (n.name === name) {
                return i;
            }
        });
        return Number(index[0]);
    }

    public getOwner(): User {
        const users = this.users.filter(n => n.usertype === 'owner');
        return users[0];
    }
    public getRemote(): User[] {
        const users = this.users.filter(n => n.usertype === 'remote');
        return users;
    }
    public getAllUser(): User[] {
        return this.users;
    }


    public getColor(): string {
        let color = Math.floor(Math.random() * 0xFFFFFF).toString(16);
        for (let count = color.length; count < 6; count++) {
            color = '0' + color;
        }
        const randomColor = '#' + color;

        return randomColor;
    }
}

export class User {
    name: string;
    userid: string;
    color: string;
    usertype: string;
    analyser: object;
    audio: object;
}
