import { Injectable, OnInit } from '@angular/core';
import { SubjectsService } from '../subjects.service';

@Injectable()
export class AudioService implements OnInit {
    private source = [];
    private animationId;
    private audioContext = [];
    private audioStream = [];
    private filter = [];

    private analyser = [];
    private gain = null;
    private canvas = [];
    private canvasContext = [];
    private drawVisual = [];
    private ffsize = 128;

    ngOnInit(): void {

    }

    public setup(target, id): void {
        this.init(id);
        this.addStream(target, id);
    }

    public addStream(stream, id): void {
        this.audioStream[id] = stream;
    }

    public addCanvas(canvas, id): void {
        // this.canvas[id] = canvas;
        // this.canvasContext[id] = this.canvas[id].getContext('2d');
    }

    public delStream(id): void {
        this.audioStream[id] = null;
        this.audioContext[id] = null;
        this.analyser[id] = null;
        this.source[id] = null;
        this.filter[id] = null;
        this.canvas[id] = null;
        this.canvasContext[id] = null;
    }

    public render(id): void {
        /*for (const key in this.audioStream) {
            if (this.audioStream.hasOwnProperty(key)) {
                this.audioRender(key);
            }
        }*/
        this.audioRender(id);
    }

    private init(id): void {
        console.log('Setup Audio API');
        const AudioContext = window['AudioContext'] || window['webkitAudioContext'];
        this.audioContext[id] = new AudioContext();
        this.analyser[id] = this.audioContext[id].createAnalyser();
        this.analyser[id].fftSize = this.ffsize;
        this.canvas[id] = document.getElementById('analyser' + id);
        this.canvasContext[id] = this.canvas[id].getContext('2d');
    }

    public audioRender(id): void {
        /*
        if (this.source[id]) {
            this.source[id].stop();
            cancelAnimationFrame(this.animationId);
        }*/
        this.filter[id] = new BiquadFilterNode(
            this.audioContext[id],
            {
                frequency: 1300,
                Q: 1
            });
        this.filter[id].type = 'lowpass';
        this.filter[id]
                .connect(this.analyser[id])
                .connect(this.audioContext[id].destination);
        this.source[id] = this.audioContext[id]
                            .createMediaStreamSource(this.audioStream[id]);
        this.source[id].connect(this.filter[id]);

        // this.visualFrequencybars(id);
        this.visualSineWave(id);
    }


    /**
     * Sine波を使用したビジュアライズ
     */
    private visualSineWave(id): void {
        this.analyser[id].fftSize = 2048;
        const bufferLength = this.analyser[id].fftSize;
        console.log(bufferLength);
        const dataArray = new Uint8Array(bufferLength);

        this.canvasContext[id].clearRect(
                0, 0,
                this.canvas[id].width, this.canvas[id].height);

        const draw = () => {
            if (this.audioContext[id].state === 'suspended') {
                this.audioContext[id].resume();
            }
            this.analyser[id].getByteTimeDomainData(dataArray);

            this.canvasContext[id].fillStyle = 'rgb(200, 200, 200)';
            this.canvasContext[id]
                .fillRect(0, 0, this.canvas[id].width, this.canvas[id].height);
            this.canvasContext[id].fill();

            this.canvasContext[id].lineWidth = 2;
            this.canvasContext[id].strokeStyle = 'rgb(0, 0, 0)';

            this.canvasContext[id].beginPath();

            const sliceWidth = this.canvas[id].width * 1.0 / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {

                const v = dataArray[i] / 128.0;
                const y = v * this.canvas[id].height / 2;

                if (i === 0) {
                    this.canvasContext[id].moveTo(x, y);
                } else {
                    this.canvasContext[id].lineTo(x, y);
                }

                x += sliceWidth;
            }
            this.canvasContext[id].lineTo(
                    this.canvas[id].width, this.canvas[id].height / 2);
            this.canvasContext[id].stroke();
            // console.log(this.canvasContext[id]);

            // this.drawVisual[id] = requestAnimationFrame(draw);
            requestAnimationFrame(draw);
        };
        draw();
    }

    private visualFrequencybars(id): void {
        this.analyser[id].fftSize = 256;
        const bufferLengthAlt = this.analyser[id].frequencyBinCount;
        console.log(bufferLengthAlt);
        const dataArrayAlt = new Uint8Array(bufferLengthAlt);

        this.canvasContext[id].clearRect(
            0, 0,
            this.canvas[id].widht, this.canvas[id].height);

        const drawAlt = () => {

            this.analyser[id].getByteFrequencyData(dataArrayAlt);

            this.canvasContext[id].fillStyle = 'rgb(0, 0, 0)';
            this.canvasContext[id].fillRect(
                0, 0,
                this.canvas[id].width, this.canvas[id].height);

            const barWidth = (this.canvas[id].width / bufferLengthAlt) * 2.5;
            let barHeight;
            let x = 0;

            for (let i = 0; i < bufferLengthAlt; i++) {
                barHeight = dataArrayAlt[i];

                this.canvasContext[id].fillStyle = 'rgb(' + (barHeight + 100) + ',50,50)';
                this.canvasContext[id].fillRect(
                    x,
                    this.canvas[id].height - barHeight / 2,
                    barWidth,
                    barHeight / 2
                    );

                x += barWidth + 1;
            }
            this.drawVisual[id] = requestAnimationFrame(drawAlt);
        };

        drawAlt();
    }
}
