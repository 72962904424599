import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    SubjectsService,
    WebSocketService,
    WebRTCService
} from '../service';
import {
    UserService, ContentService
} from '../service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';


@Component({
  selector: 'app-screen',
  templateUrl: './vochan.component.html',
  styleUrls: [
      '../app.component.css',
      './vochan.component.scss'
    ]
})

export class VochanComponent implements OnInit {

    private URL = 'http://happypazdra.dip.jp:8088/janus';
    private URLS = 'https://happypazdra.dip.jp:8089/janus';

    private server = null;
    private videoElement = null;
    private remoteElement = [];

    private audioBox = document.getElementById('audioBox');
    private analyserBox = document.getElementById('analyserBox');

    public onStart = false;
    public onwebrtcUp = false;

    public desc = '';
    public myusername = '';
    public feeds = [];
    public role = '';

    public name = 'Guest';
    public userColor = '';

    canvasColorList = [
      '#E60012', '#F39800', '#FFF100', '#8FC31F', '#009944',
      '#00A0E9', '#1D2088', '#E4007F', '#E5004F',
      '#808080', '#000000', '#FFFFFF'
    ];

    public session = '';
    public title = '';

    public source = null;

    public myvideoState = {
        'width': 1024,
        'height': 600,
        'muted': 'muted',
    };
    public peervideoState = {
        'width': 320,
        'height': 240,
    };

    public roomname: string = null;
    /**
     * contributor  : 映像・音声の配信
     * video        : 映像のみ
     * audio        : 音声の配信
     * listener     : 視聴のみ
     *  */
    public mode = 'audio';

    public showBitrate = 0;
    constructor(
        // private janusService: Janus,
        private renderer2: Renderer2,
        private router: ActivatedRoute,
        private subjectService: SubjectsService,
        private websocketService: WebSocketService,
        private webrtcService: WebRTCService,
        private userService: UserService,
        private contentService: ContentService
    ) {}

    ngOnInit(): void {
        this.initial();
        // this.setup();
        this.setRoomName();
    }

    private hub(): void {
        this.subjectService.on('on_leave')
        .subscribe((msg: any) => {
          console.log(msg);
          this.userService.delUserByUserId(msg['data']['id']);
          this.delAudioElement(msg['data']['id']);
        });
        this.subjectService.on('on_allusers')
        .subscribe((msg: any) => {
            console.log(msg);
            this.userService.addMultiUser(msg);
        });
        this.subjectService.on('on_sys')
        .subscribe((msg: any) => {
            if (msg === 'connected') {
                this.startStream();
            }
        });
        this.subjectService.on('on_webrtc')
        .subscribe((msg: any) => {
            this.webrtcManager(msg);
        });
        this.subjectService.on('on_' + this.roomname)
            .subscribe((msg: any) => {
                this.socketHub(msg);
            });
    }

    private socketHub(msg: any): void {
        if (msg['msg'] === 'webrtc') {
            this.webrtcManager(msg);
        }
    }

    /**
     * 部屋名が設定されている場合クライアント処理開始
     */
    private setRoomName(): void {
        this.webrtcService.checkMediaDevice();
        const room = this.router.snapshot.queryParams;
        if (room.hasOwnProperty('room')) {
            console.log(room);
            this.roomname = room['room'];
            // ゲストの接続モード判定
            this.setVideoMode();
        }
    }

    private setVideoMode(): void {
        if (this.webrtcService.checkMediaDevice()) {
            this.webrtcService.checkMediaDevice().then(
                (result) => {
                    if (result['audio']) {
                        this.mode = 'audio';
                    } else {
                        this.mode = 'listener';
                    }
                }
            );
        } else {
            this.mode = 'listener';
        }
    }

    /**
     * WebSocketセットアップ
     */
    private setupSocket(): void {
        this.websocketService.setColor(this.userColor);
        this.websocketService.setRoomName(this.roomname);
        this.websocketService.setNameSpace('test1');
        this.websocketService.setName(this.name);
        this.websocketService.connection(
            [
                this.roomname, 'allusers'
            ]
        );
    }

    /**
     *
     * 画面切り替え
     *
     */

    public showContent(target): boolean {
        return this.contentService.checkShow(target);
    }

    public changeContent(target: string, bool: boolean = null): void {
        this.contentService.changeState(target, bool);
    }


    /**
     *
     * 接続中ユーザー
     *
     */
    public getOwner(): object {
        return this.userService.getOwner();
    }
    public getUsers(): object {
        return this.userService.getRemote();
    }
    public getAllUsers(): object {
        return this.userService.getAllUser();
    }

    /**
     *
     * WebRTC処理
     *
     */

    public checkEnterShare(event): boolean {
        const theCode = event.charCode;
        if (theCode === 13) {
            this.preShareScreen();
            return false;
        } else {
            return true;
        }
    }

    /**
     * 部屋名入力後の共有画面取得開始
     */
    public preShareScreen(): void {
        // Create a new room
        this.contentService.changeState('ScreenMenu', false);
        if (this.desc === '') {
            // before bootbox alert
            console.log('Please insert a description for the room');
            this.contentService.screenReset();
            return;
        }
        this.title = this.desc;

        // ブラウザがgetUserMediaに対応しているか判定
        if (this.webrtcService.checkScreenShare()) {
            this.contentService.changeState('ScreenSelect', true);
        } else {
            alert('Not Support');
        }
    }

    /**
     * クライアントを配信に参加させる
     */
    public joinScreen(): Promise<boolean> {
        if (isNaN(Number(this.roomname))) {
            // before bootbox alert
            console.log('Session identifiers are numeric only');
            return;
        }
        this.myusername = this.name;

        if (this.mode === 'audio') {
            const audio = document.getElementById('myaudio');
            this.webrtcService.setVideoTarget(audio);
            return new Promise((resolve) => {
                if (this.webrtcService.checkScreenShare()) {
                    this.webrtcService.getLocalStream(false, true)
                    .then((result) => {
                        this.webrtcService.playVideo('local');
                        resolve(result);
                    });
                } else {
                    resolve(false);
                }
            });
        } else {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(false);
                }, 10);
            });
        }
    }

    /**
     * httpとhttpsに応じてサーバー接続先切り替え
     */
    private initial(): void {
        console.log(window.location.protocol);
        if (window.location.protocol === 'http:') {
            this.server = this.URL;
        } else if (window.location.protocol === 'https:') {
            this.server = this.URLS;
        }
        this.audioBox = document.getElementById('audioBox');
        this.analyserBox = document.getElementById('analyserBox');
    }

    /**
     * スタート処理
     */
    public start(): void {
        this.onStart = true;
        this.userColor = this.userService.getColor();
        this.contentService.showStartAudio();

        if (this.mode === 'listener' || this.mode === 'audio') {
            // ローカルストリーム表示
            this.webrtcService.setVideoMode(this.mode);

            if (this.roomname === null) {
                this.roomname = String(this.webrtcService.getRandomNumber(8));
                // this.roomname = '1234';
            }
            // websocket受信待受
            this.hub();
            // websocket 接続開始
            this.setupSocket();
        }
    }

    public stop(): void {
        this.onStart = false;
        this.shutdown();
    }


    /**
     * ストリームを止める
     */
    public shutdown(): void {
        this.videoElement.pause();
        if (this.videoElement.src && (this.videoElement.src !== '') ) {
            window.URL.revokeObjectURL(this.videoElement.src);
        }
        this.videoElement.src = '';
    }

    /**
     * websocket接続確立後に
     * WebRTCコネクション開始処理を始める
     */
    public startStream(): void {
        // 自分のIDを登録
        this.userService.setOwnId(this.websocketService.getUserId());
        // WebRTC接続開始
        this.joinScreen().then((result) => {
            console.log('Start Stream for ' + this.mode);
            this.websocketService.send(
                this.roomname,
                {
                    'msg': 'webrtc',
                    'job': 'request_offer',
                    'mode': this.mode
                }
            );
        });
    }

    /**
     * WebRTC通信止める
     */
    public stopStream(): void {
        this.webrtcService.hungUp();
    }

    /**
     * webrtc通信を開通まで導く部分
     * @param result 処理させるデータオブジェ
     */
    private webrtcManager(result): void {
        if (result['job'] === 'send_sdp') {
            this.websocketService.send(
            this.roomname,
            {
                'msg': 'webrtc',
                'job': 'remote_sdp',
                'data': result['data'],
                'to': result['id'],
                'mode': this.mode
            });

        } else if (result['job'] === 'remote_sdp') {
            const data = JSON.parse(result['data']);
            if ('id' in result) {
                if (this.webrtcService.checkAuthConnection(result['id'])) {
                    this.addAudioElement(result['id']);
                }
                this.webrtcService.onSdpText(data, result['id']);
            }
        } else if (result['job'] === 'request_offer') {
            console.log('get offer request');
            console.log(result);
            if (this.webrtcService.checkAuthConnection(result['id'])) {
                this.webrtcService.makeOffer(result['id']);
                console.log('Add Audio Element');
                this.addAudioElement(result['id']);
            }
        }
    }

    private addAudioElement(id): void {
        if (!this.remoteElement[id]) {
            const users = document.getElementById('users');
            const user = this.userService.getUserById(id);
            // ユーザーボックス要素作成
            const usertag = this.renderer2.createElement('div');
            usertag.id = 'user_' + id;
            usertag.setAttribute('class', 'members');
            // ユーザー名要素作成
            const usernamebox = this.renderer2.createElement('div');
            this.renderer2.addClass(usernamebox, 'member-detail');
            const username = this.renderer2.createElement('p');
            this.renderer2.setStyle(usernamebox, 'background-color', user.color);

            const name = this.renderer2.createText(user.name);
            username.appendChild(name);
            usernamebox.appendChild(username);
            usertag.appendChild(usernamebox);

            // canvas要素作成
            const canvas = this.renderer2.createElement('canvas');
            canvas.id = 'analyser' + id;
            canvas.height = 40;
            this.analyserBox.appendChild(canvas);
            // 各要素の登録
            // audio要素作成
            const audio = this.renderer2.createElement('audio');
            audio.id = 'remote_video_' + id;
            audio.setAttribute('class', 'hideon');
            audio.autoplay = true;
            audio.playsinline = true;
            this.audioBox.appendChild(audio);
            this.remoteElement[id] = audio;
            this.webrtcService.setRemoteVideoTarget(audio, id);

            usertag.appendChild(canvas);
            users.appendChild(usertag);

            this.userService.addUserTag(usertag, id);
            // this.webrtcService.setVisualyserTarget(canvas, id);
            // this.userService.setAnalyser(canvas, id);
        }
    }

    private delAudioElement(id): void {
        const users = document.getElementById('users');
        const tag = this.userService.getUserTag(id);
        this.renderer2.removeChild(users, tag);
        this.userService.delUserTag(id);
    }

    /**
     * 録画再生
     */

    public start_recorde(id): void {
        // const hideo = document.getElementById(id);
        // this.webrtcService.setRecordePlayer(hideo);
        this.webrtcService.startRecord();
    }

    public stop_recorde(): void {
        this.webrtcService.stopRecord();
    }

    public play_recorde(id): void {
        this.webrtcService.plyaRecord(document.getElementById(id));
    }

    public dl_recorde(id): void {
        const dl: any = document.getElementById(id);
        dl.download = 'hideo.webm';
        dl.href = this.webrtcService.getRecordeURL();
    }

    private AllReset(): void {
        this.contentService.screenReset();

        this.onStart = false;

        this.desc = '';
        this.myusername = '';
        this.role = '';

        this.session = '';
        this.title = '';

        this.source = null;
    }
}


